import { defineStore } from "pinia"
import {
  Camera,
  Project,
  ProjectExid,
  ProjectsByExid,
  ProjectStatus,
} from "@evercam/shared/types"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useBatteryReadingsStore } from "@evercam/dashboard/stores/batteryReadings"
import { useCameraStore } from "@evercam/dashboard/stores/camera"
import axios, { AxiosError } from "axios"
import { useNuxtApp, useRuntimeConfig } from "#app"

type ProjectState = {
  projects: Project[] | []
  filteredProjects: Project[] | []
  selectedProject: Project | null
  isFetchingProjects: boolean
  isArchiveProject: boolean
  searchProjectEnabled: boolean
  tPulseLink: string
}

const getSortedCameras = (cameras) => {
  return (cameras || []).sort((a, b) => {
    const cameraNameA = a?.name || ""
    const cameraNameB = b?.name || ""

    return cameraNameA?.localeCompare(cameraNameB, "en", {
      numeric: true,
    })
  })
}

export const useProjectStore = defineStore("project", {
  state: (): ProjectState => ({
    projects: [],
    filteredProjects: [],
    selectedProject: null,
    isFetchingProjects: false,
    searchProjectEnabled: false,
    isArchiveProject: false,
    tPulseLink: null,
  }),
  actions: {
    clearSelectedProject() {
      this.selectedProject = null
    },
    async fetchUserProjects() {
      try {
        this.isFetchingProjects = true
        const { data } = await EvercamApi.projects.index()
        this.projects = data.sort((a, b) =>
          a.name
            .replace(/(\n|\t|\r|\s)+/gm, "")
            .localeCompare(b.name.replace(/(\n|\t|\r|\s)+/gm, ""))
        )
        this.filteredProjects = this.projects
      } catch (error) {
        if (
          error instanceof AxiosError &&
          error.response?.status === 500 &&
          !useNuxtApp().nuxt2Context.isDev
        ) {
          useNuxtApp().nuxt2Context.app.router.push("/server-down")
        } else {
          console.error(error)
        }
      } finally {
        this.isFetchingProjects = false
      }
    },
    async selectProject(exid) {
      const cameraStore = useCameraStore()
      let cameras: Camera[] = cameraStore.cameras as Camera[]
      if (!cameras?.length) {
        await cameraStore.fetchUserCameras()
        cameras = cameraStore.cameras as Camera[]
      }
      useBatteryReadingsStore().fetchBatteryReadings(exid)
      let project = this.getProjectByExid(exid)
      if (project && Object.keys(project)?.length && cameras?.length) {
        const projectCameras = getSortedCameras(
          cameras?.filter((camera: Camera) => camera.project.id === exid)
        )
        project = {
          ...project,
          cameras: projectCameras,
        }
        this.isArchiveProject = project.status == ProjectStatus.Completed
        this.selectedProject = project
      }
    },
    getProjectByExid(exid): Project {
      return this.projects?.find((project) => project?.exid == exid) as Project
    },
    async fetchProjectTPulseLink() {
      if (!this.selectedProject?.exid) {
        return
      }

      this.tPulseLink = null
      const { data } = await axios.get(
        `${useRuntimeConfig().firebaseDbLink}data/projects/safety/${
          this.selectedProject.exid
        }.json`
      )
      this.tPulseLink = data?.link
    },
  },
  getters: {
    activeProjects(): Project[] {
      return (this.projects as Project[])?.filter(
        (project) => project.status !== ProjectStatus.Completed
      )
    },
    completedProjects(): Project[] {
      return (this.projects as Project[])?.filter(
        (project) => project.status === ProjectStatus.Completed
      )
    },
    selectedProjectExid: (state): ProjectExid => state.selectedProject?.exid,
    selectedProjectName: (state): string => state.selectedProject?.name,
    selectedProjectCameras: (state): Camera[] =>
      (state.selectedProject?.cameras || []) as Camera[],
    selectedProjectTimezone: (state): string =>
      state.selectedProject?.timezone || "Europe/Dublin",
    projectRoute(): string {
      return `/v2/projects/${this.selectedProjectExid}`
    },
    projectsByExid(): ProjectsByExid {
      return this.projects.reduce(
        (acc, project) => ({
          ...acc,
          [project.exid]: project,
        }),
        {}
      )
    },
  },
})
